export default {
    seats: state => state.seats,
    oneWayBookingMinutesTimer: state => state.oneWayBookingMinutesTimer,
    oneWayBookingShowTimer: state => state.oneWayBookingShowTimer,
    oneWayTotalAmount: state => state.oneWayTotalAmount,
    oneWayTotalPrice: state => state.oneWayTotalPrice,
    oneWayTotalDiscount: state => state.oneWayTotalDiscount,
    oneWayTotalVoucher: state => state.oneWayTotalVoucher,
    voucher: state => state.voucher,
    userSelectedTrip: state => state.userSelectedTrip,
}